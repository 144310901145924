<template>
  <div>
    <intro v-if="thisTabs == 'tab1'"></intro>
    <div  v-if="thisTabs == 'tab2'">
      <h1>Coming Soon</h1>
    </div>
    <!-- <schedule v-if="thisTabs == 'tab2'"></schedule> -->
    <!-- <playoff v-if="thisTabs == 'tab2'"></playoff> -->
  </div>
</template>

<script>
import intro from '@/components/content/intro'
import schedule from '@/components/content/schedule'
import playoff from '@/components/content/playoff'

export default {
  name: "sml",
  components: {intro, schedule, playoff},
  data() {
    return {
      thisTabs: this.$parent.thisTabs
    }
  },
  mounted: function () {
    this.$bus.$on('goBack', () => {
      this.thisTabs = ''
      this.$router.push({name: 'Home'}).catch(() => {
      });
    })
    this.$bus.$on('chooseTabs', (el) => {
      this.thisTabs = el
      if(el !== 'tab3'){
        this.$router.push({name: 'Home'}).catch(() => {
        });
      }
      window.scrollTo(0, 0);
    })
  },
}
</script>

<style lang="scss">
h1{
  color: $main-text-1;
  width: 100%;
  text-align: center;
}
.content {
  padding-top: 90px;

  .logo {
    width: 100%;
  }

  span {
    font-family: 'Hiragino Kaku Gothic Std';
    font-size: 2rem;
    display: block;
    margin-bottom: 2rem;
  }
}


</style>