<template>
  <div class="tabs">
    <ul>
      <li :class="active == 'tab1'?'active':''" @click="chooseTabs('tab1')">
        <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5983 10.7928L27.5647 10.7928C29.0453 10.7928 30.1396 11.4044 30.1396 16.9381L30.1396 3.06189C30.1396 8.59564 29.0345 9.20716 27.5647 9.20716L14.5983 9.20716C13.4675 9.20716 11.1395 9.14065 11.1395 7.3211C11.2575 3.78929 12.7788 -7.99497e-07 12.7788 -7.99497e-07C12.7788 -7.99497e-07 10.0494 4.32786 7.73209 6.36627C4.98132 8.79305 2.22838 10.0011 0.818664 10.0011C2.22838 10.0011 4.98132 11.2091 7.73209 13.6337C10.0494 15.6807 12.7788 20 12.7788 20C12.7788 20 11.2575 16.2107 11.1395 12.6789C11.1502 10.8593 13.4675 10.7928 14.5983 10.7928Z" fill="#D0D3CE"/>
        </svg>
        <span>簡介</span>
      </li>
      <li :class="active == 'tab2'?'active':''" @click="chooseTabs('tab2')">
        <span>賽事</span>
        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.2204 9.20716L3.25393 9.20716C1.7734 9.20716 0.679096 8.59564 0.679096 3.06189L0.679095 16.9381C0.679096 11.4044 1.78413 10.7928 3.25393 10.7928L16.2204 10.7928C17.3511 10.7928 19.6792 10.8593 19.6792 12.6789C19.5612 16.2107 18.0399 20 18.0399 20C18.0399 20 20.7692 15.6721 23.0866 13.6337C25.8373 11.207 28.5903 9.99893 30 9.99893C28.5903 9.99893 25.8373 8.7909 23.0866 6.36627C20.7692 4.31928 18.0399 -5.22793e-07 18.0399 -5.22793e-07C18.0399 -5.22793e-07 19.5612 3.78929 19.6792 7.3211C19.6685 9.14065 17.3511 9.20716 16.2204 9.20716Z" fill="#D0D3CE"/>
        </svg>
      </li>
<!--      <li :class="active == 'tab3'?'active':''" @click="chooseTabs('tab3')"><span>報名</span></li>-->
    </ul>
  </div>
</template>

<script>
  export default {
    name: "tabs",
    props: ['active'],
    data() {
      return {}
    },
    methods: {
      chooseTabs: function (el) {
        var tab = (this.active == el) ? '' : el;
        this.$bus.$emit('chooseTabs', tab)
      },
    },
    mounted: function () {
      // var $this =this

    }
  }
</script>

<style scoped lang="scss">
$tabsBgLeft: $main-color-1;
$tabsBgRight: $main-color-2;
$tabsColor:$main-text-1;

  .tabs {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex: 1;
        height: 2.5rem;
        text-align: center;
        color: $tabsColor;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 900;
        cursor: pointer;
        font-family: Hiragino Kaku Gothic Std;
        transition-delay: .5s;
        transition: all 1s linear;
        &:first-child{
          background: $tabsBgLeft;
          justify-content: flex-start;
          padding-left: 1rem;
        }
        &:last-child {
          background: $tabsBgRight;
          justify-content: flex-end;
          padding-right: 1rem;

        }

        &.active {
          flex: 3;
        }
      }
    }
  }
</style>
