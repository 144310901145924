<template>
  <div class="top-banner">
    <div class="origin-banner" :class="showTab.length == 0? 'active':''" :style="{'background-image':`url(${banner})`}"></div>
    <div class="lite-banner" :class="showTab.length !== 0? 'active':''" :style="{'background-image':`url(${litebanner})`}" @click="goBack()"></div>
  </div>
</template>

<script>
  const banner = require('@/assets/images/xuanwu/xuanwu_banner.png');
  const litebanner = require('@/assets/images/xuanwu/banner.png');
  export default {
    name: "topHeader",
    props: ['scrolled', 'scrollNum', 'showTab'],
    data() {
      return {
        banner: banner,
        litebanner: litebanner,
        mobNavActive: false,
      }
    },
    methods: {
      goBack: function () {
        this.$bus.$emit('goBack')
      }
    },
    watch: {
      // '$route': {
      //   handler: function () {
      //     this.mobNavActive = false
      //   },
      //   deep: true,
      //   immediate: true
      // },

    }
  }
</script>

<style lang="scss">
  @import '../../assets/style/header';
</style>
